/* eslint-disable react/prop-types, react/no-unstable-nested-components, react/jsx-no-bind, no-unused-vars */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import algoliasearch from 'algoliasearch/lite';
import {
  Configure,
  InstantSearch,
  SearchBox,
  Pagination,
} from 'react-instantsearch-dom';
import ReporterSearchHit from './reporter_search_hit';
import HiddenToggle from './hidden_toggle';

function ReporterSearch({
                          algoliaAppId, algoliaSearchKey, isAdmin, indexName, query, underAudit,
                        }) {
  const searchClient = algoliasearch(algoliaAppId, algoliaSearchKey);
  const hits = useRef([]);
  const [hitsLength, setHitsLength] = useState(null);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [selectedReporterGids, setSelectedReporterGids] = useState({});
  const [showHidden, setShowHidden] = useState(false);

  const colSpan = isAdmin ? 4 : 3;

  const setHits = (hts) => {
    hits.current = hts;
    setHitsLength(hts.length);
  };

  const toggleReporter = (gid) => {
    setSelectedReporterGids({
      ...selectedReporterGids,
      [gid]: !selectedReporterGids[gid],
    });
  };

  const handleSelectAllChange = (checked) => {
    if (checked) {
      const allReportersSelected = hits.current.reduce((selections, hit) => ({
        ...selections,
        [hit.data_source]: true,
      }), {});
      setSelectedReporterGids(allReportersSelected);
    } else {
      setSelectedReporterGids({});
    }
    setSelectAllChecked(checked);
  };

  let downloadCsvUrl = '';
  const selectedGidsArray = Object.entries(selectedReporterGids)
    .filter(([gid, isSelected]) => isSelected)
    .map(([gid]) => gid);

  if (selectedGidsArray.length > 0) {
    const downloadReportersParam = encodeURIComponent(selectedGidsArray.join(','));
    downloadCsvUrl = `/media_db_reporters?format=csv&reporter_ids=${downloadReportersParam}`;
  }

  const handleDownloadClick = () => {
    if (downloadCsvUrl.length > 0) {
      const newWindow = window.open(downloadCsvUrl, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
    }
  };

  const getTopLevelFilters = () => {
    const filterParts = [];

    if (showHidden) {
      filterParts.push('visibility:Hidden', 'NOT visibility:Visible');
    } else {
      filterParts.push('NOT visibility:Hidden');
    }
    if (underAudit) {
      filterParts.push('NOT qwoted_user:true');
    }
    return filterParts.join(' AND ');
  };

  const topLevelFilters = getTopLevelFilters();

  return (
    <InstantSearch
      indexName={indexName}
      searchClient={searchClient}
      onSearchStateChange={(searchState) => {
        setSelectedReporterGids({});
        setSelectAllChecked(false);
      }}
    >
      <Configure hitsPerPage={150} filters={topLevelFilters} />

      <div className="d-block d-md-flex justify-content-between align-items-center my-2">
        <div className="flex-grow-1">
          <button
            type="button"
            className={`btn btn-link ${downloadCsvUrl.length === 0 ? 'disabled' : ''}`}
            onClick={handleDownloadClick}
            disabled={downloadCsvUrl.length === 0}
          >
            <i className="fa fa-download" />&nbsp;Download CSV
          </button>
        </div>

        <div className="d-flex justify-content-end align-items-center flex-grow-1" style={{ maxWidth: '40em' }}>
          {isAdmin && (<><HiddenToggle showHidden={showHidden} setShowHidden={setShowHidden} />&nbsp;</>)}
          <SearchBox className="w-100" defaultRefinement={query} />
        </div>
      </div>

      <div className="overflow-auto">
        <table className="table table-striped align-middle">
          <thead>
          <tr className="align-middle">
            <th className="d-none d-md-table-cell ps-2 border-0 border-bottom-md">
              <input
                type="checkbox"
                className="form-check-input align-middle mt-0 me-2"
                checked={selectAllChecked}
                onChange={(e) => handleSelectAllChange(e.target.checked)}
              />
              <div className="d-none d-lg-inline small">
                Select All
              </div>
            </th>

            <th className="d-xl-table-cell d-none border-0 border-bottom-md small">
              Title
            </th>

            <th className="d-xl-table-cell d-none border-0 col-md-2 border-bottom-md small">
              Publication(s)
            </th>

            <th className="d-md-table-cell d-xl-none d-none border-0 col-md-2 border-bottom-md small">
              Title and Publication(s)
            </th>

            <th className="d-none d-md-table-cell col-md-2 border-0 border-bottom-md small">
              Covers
            </th>

            <th colSpan="2" className="d-none d-md-table-cell pe-0">
              <div className="d-flex align-items-end flex-column">
                <div className="font-size-10px text-danger">
                  <i className="fa fa-flag" />
                  &nbsp;
                  <a className="text-decoration-underline text-danger" href="mailto:MediaDBSupport@qwoted.com">
                    Incorrect info? Let us know
                  </a>
                </div>
              </div>
            </th>

            {isAdmin && <th />} {/* eslint-disable-line jsx-a11y/control-has-associated-label */}
          </tr>
          </thead>
          <tbody>
          {hitsLength === 0 && (
            <tr>
              <td colSpan="999"><h4 className="font-weight-bold m-0">Nothing found matching this search.</h4></td>
            </tr>
          )}
          <ReporterSearchHit
            toggleReporter={toggleReporter}
            selectedReporterGids={selectedReporterGids}
            selectAllChecked={selectAllChecked}
            setHits={setHits}
            isAdmin={isAdmin}
          />
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-center mt-3">
        <Pagination />
      </div>
    </InstantSearch>
  );
}

ReporterSearch.propTypes = {
  algoliaAppId: PropTypes.string.isRequired,
  algoliaSearchKey: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  indexName: PropTypes.string.isRequired,
  underAudit: PropTypes.bool.isRequired,
};

export default ReporterSearch;
/* eslint-enable react/prop-types, react/no-unstable-nested-components, react/jsx-no-bind, no-unused-vars */
