import React from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import PropTypes from 'prop-types';
import Avatar from '../avatar/avatar';
import CollapsePitchText from './collapse_pitch_text';
import vapi from '../../javascript/frontend/api/vapi';
import vahoy from '../../javascript/vahoy';

class FollowupModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: props.userId,
      email: props.email,
      token: props.token,
      toSguid: props.toSguid,
      fields: {
        body: props.emailBody,
        subject: props.subject,
      },
      reportedText: props.reportedText,
      showPopup: props.showPopup,
    };
    this.close = this.close.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.showPopup != state.showPopup) {
      return {
        showPopup: props.showPopup,
        fields: {
          body: props.emailBody,
          subject: props.subject,
        },
      };
    }

    return {};
  }

  handleChange = (field, event) => {
    const { fields } = this.state;
    fields[field] = event.target.value;
    this.setState({
      fields,
    });
  };

  sendMessage = () => {
    const {
      setMessagePath,
      closeModal,
    } = this.props;
    const {
      userId,
      email,
      token,
      toSguid,
      fields,
    } = this.state;
    const {
      subject,
      body,
    } = fields;
    vapi.createMessage(userId, email, token, toSguid, subject, body)
      .then((result) => {
        setMessagePath(result.data.path);
        closeModal();
      }).catch((error) => error);

    vahoy.track('FollowupModal#sendMessage');
  };

  close = () => {
    const { closeModal } = this.props;
    const { fields } = this.state;
    const { body } = fields;

    if (body) {
      if (window.confirm('You will lose your message?')) {
        closeModal();
      }
    } else {
      closeModal();
    }
  };

  render() {
    const {
      fields,
      reportedText,
      showPopup,
    } = this.state;
    const {
      subject,
      body,
    } = fields;
    const {
      sourceName,
      sourceEmployment,
      sourceSlug,
      avatarUrl,
      avatarLetters,
      proposedEntities,
    } = this.props;

    const closeBtn = (
      <button type="button" className="btn-close" aria-label="Close" onClick={this.close} />
    );

    const closeBtnHidden = (
      <button style={{ visibility: 'hidden' }} type="button" className="btn-close" aria-label="Close" onClick={this.close} />
    );

    /* eslint-disable react/no-danger */
    return (
      <Modal className="modal-lg" isOpen={showPopup} toggle={this.close} backdrop="static">
        <ModalHeader toggle={this.close} close={closeBtn} className="quick-reply-modal-header">
          <div className="row">
            <div className="col-6">
              Reply to {sourceName}'s pitch
            </div>
            <div className="col-6">
              <div className="card-body pb-2">
                <div className="float-start pe-2">
                  {avatarUrl && avatarLetters && (
                    <Avatar
                      avatarUrl={avatarUrl}
                      avatarLetters={avatarLetters}
                      sizeClass="avatar-xs"
                    />
                  )}
                </div>
                <div>
                  <h6 className="mb-0 fw-bold">
                    <a href={`/sources/${sourceSlug}`} target="_blank" rel="noreferrer">{sourceName} (see profile)</a>
                  </h6>
                  <div className="mb-2" />
                  <span dangerouslySetInnerHTML={{ __html: sourceEmployment }} />
                </div>
              </div>
            </div>
          </div>
        </ModalHeader>

        <ModalHeader close={closeBtnHidden} className="quick-reply-modal-header">
          <div className="row">
            <div className="col-12">
              <CollapsePitchText pitchText={reportedText} proposedEntities={proposedEntities} />
            </div>
          </div>
        </ModalHeader>

        <ModalBody>
          <div className="row">
            <div className="col-12">
              <div className="alert alert-light-purple">
                <i className="fa-regular fa-circle-info header" />&nbsp;<strong>Note:</strong> Qwoted recommends scheduling a video interview to validate expertise.
              </div>
            </div>
            <label className="col-2 col-form-label">To</label>
            <div className="col-10 mb-1">
              <input
                id="source-name"
                className="form-control"
                value={sourceName}
                readOnly
                required
              />
            </div>

            <label className="col-2 col-form-label">Subject</label>
            <div className="col-10 mb-3">
              <input
                id="subject"
                className="form-control"
                type="subject"
                value={subject}
                onChange={this.handleChange.bind(this, 'subject')}
                disabled
              />
            </div>

            <div className="col-12">
              <textarea
                id="email-body"
                className="form-control"
                name="email-body"
                placeholder="Your message"
                value={body}
                onChange={this.handleChange.bind(this, 'body')}
                rows="10"
                required
              />
            </div>
          </div>
        </ModalBody>

        <ModalFooter>
          <Button color="primary" onClick={this.sendMessage}>Send</Button>
          <Button onClick={this.close}>Close</Button>
        </ModalFooter>
      </Modal>
    );
    /* eslint-enable react/no-danger */
  }
}

FollowupModal.propTypes = {
  userId: PropTypes.number,
  email: PropTypes.string,
  token: PropTypes.string,
  toSguid: PropTypes.string,
  emailBody: PropTypes.string,
  subject: PropTypes.string,
  sourceName: PropTypes.string,
  sourceEmployment: PropTypes.string,
  sourceSlug: PropTypes.string,
  avatarUrl: PropTypes.string,
  avatarLetters: PropTypes.string,
  proposedEntities: PropTypes.array,
  reportedText: PropTypes.string,
  showPopup: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  setMessagePath: PropTypes.func.isRequired,
};

FollowupModal.defaultProps = {
  userId: undefined,
  email: undefined,
  token: undefined,
  toSguid: undefined,
  emailBody: undefined,
  subject: undefined,
  sourceName: undefined,
  sourceEmployment: undefined,
  sourceSlug: undefined,
  avatarUrl: undefined,
  avatarLetters: undefined,
  proposedEntities: undefined,
  reportedText: undefined,
  showPopup: undefined,
};

export default FollowupModal;
