import React from 'react';
import PropTypes from 'prop-types';
import SectionAddLinks from './section_add_links';
import { useButtonCookie } from '../../form_cookie';

function SectionLogistics({
                            autosave,
                            equipmentNotes,
                            links,
                            logisticsDetails,
                            setEquipmentNotes,
                            setLinks,
                            setLogisticsDetails,
                            requestType,
                            subType,
                            open,
                            editMode,
                          }) {
  const [sourceRequestLogistics, setSourceRequestLogistics] = useButtonCookie('source-request-logistics', []);

  const toggleItem = (array, item, setter) => {
    if (array.includes(item)) {
      setter(array.filter((value) => value !== item));
    } else {
      setter([...array, item]);
    }
  };

  const updateLogisticsDetails = (selectedOption) => {
    toggleItem(logisticsDetails, selectedOption, setLogisticsDetails);

    if (!editMode) {
      toggleItem(sourceRequestLogistics, selectedOption, setSourceRequestLogistics);
    }
  };

  const humanizeOption = (option) => option.replace('_', ' ');

  const renderOption = (option) => {
    let activeClass;

    const logistics = editMode ? logisticsDetails : sourceRequestLogistics;

    if (logistics.includes(option.text)) {
      activeClass = ' active ';
    } else {
      activeClass = '';
    }

    return (
      <button
        type="button"
        key={option.text}
        value={option.text}
        className={`btn btn-outline-primary text-uppercase me-2 mb-2 ${activeClass}`}
        style={{
          borderWidth: '2px',
          letterSpacing: '2px',
        }}
        onClick={() => updateLogisticsDetails(option.text)}
      >
        {option.icon}
        <span style={{ marginLeft: '8px' }}>{humanizeOption(option.text)}</span>
      </button>
    );
  };

  const displayText = () => {
    switch (subType) {
      case 'radio_podcast':
      case 'video_broadcast':
        return {
          label: 'Equipment Notes',
          subtext:
            'Describe any special equipment that guests need and or any other important details about '
            + 'equipment needs.',
          options: [
            { text: 'remote', icon: <i className="fa-solid fa-house" /> },
            { text: 'in_person', icon: <i className="fa-solid fa-user" /> },
            { text: '+_video', icon: <i className="fa-solid fa-video" /> },
            { text: 'pre_recorded', icon: <i className="fa-solid fa-file-video" /> },
            { text: 'live', icon: <i className="fa-solid fa-camcorder" /> },
          ],
        };
      case 'event':
        return {
          label: 'Event details',
          subtext:
            'Describe the history of the event, the audience and expected date of event.',
          options: [
            { text: 'remote', icon: <i className="fa-solid fa-house" /> },
            { text: 'in_person', icon: <i className="fa-solid fa-user" /> },
            { text: 'travel_covered', icon: <i className="fa-solid fa-car" /> },
            { text: 'accomodations_covered', icon: <i className="fa-solid fa-house" /> },
          ],
        };
      default:
        return {};
    }
  };

  function displayable() {
    if (requestType == 'speakers') {
      return false;
    }

    const allowedSubtypes = ['radio_podcast', 'video_broadcast', 'event'];

    if (!allowedSubtypes.includes(subType)) {
      return false;
    }

    // Get the options for the current subType
    const { options } = displayText();

    if (!options || !options.length) {
      return false; // No applicable options to select
    }

    // Determine selected options based on edit mode
    const selectedOptions = editMode ? logisticsDetails : sourceRequestLogistics;
    if (selectedOptions.length === 0) {
      return true;
    }

    // Check if any of the current subType's options are selected
    return options.some((option) => selectedOptions?.includes(option.text));
  }

  const renderDetails = () => {
    const { label, subtext, options } = displayText();

    return (
      <>
        <div className="mb-3 mt-4">
          <label>
            <span className="fw-bold">Logistics</span>
            <span className="text-secondary ms-2">Select all that apply</span>
          </label>
          <br />
          {options?.map((option) => renderOption(option))}
        </div>

        <label htmlFor="details">
          <span className="fw-bold">{label}</span>
        </label>

        <textarea
          className="w-100 form-control mb-3"
          placeholder={subtext}
          defaultValue={equipmentNotes}
          id="equipmentNotes"
          name="equipmentNotes"
          onBlur={autosave}
          onInput={(e) => setEquipmentNotes(e.target.value)}
          rows="4"
        />

        <SectionAddLinks
          autosave={autosave}
          links={links}
          setLinks={setLinks}
        />
      </>
    );
  };

  if (!displayable() || !open) {
    return null;
  }

  return (
    <>
      <hr className="w-100" />

      <div className="px-2 px-md-5 py-3">
        <h4 className="mb-3 cursor-pointer">
          Add Logistic Details
        </h4>

        {renderDetails()}
      </div>
    </>
  );
}

SectionLogistics.propTypes = {
  autosave: PropTypes.func,
  equipmentNotes: PropTypes.string,
  links: PropTypes.array,
  logisticsDetails: PropTypes.array,
  setEquipmentNotes: PropTypes.func,
  setLinks: PropTypes.func,
  setLogisticsDetails: PropTypes.func,
  requestType: PropTypes.string.isRequired,
  subType: PropTypes.string,
  open: PropTypes.bool.isRequired,
  editMode: PropTypes.bool,
};

SectionLogistics.defaultProps = {
  autosave: undefined,
  equipmentNotes: undefined,
  links: undefined,
  logisticsDetails: undefined,
  setEquipmentNotes: undefined,
  setLinks: undefined,
  setLogisticsDetails: undefined,
  subType: undefined,
  editMode: false,
};

export default SectionLogistics;
