import React from 'react';
import Cookies from 'js-cookie';

export const useCheckboxCookie = (cookieName, initialValue) => {
  const cookieValue = Cookies.get(cookieName);

  const [value, setValue] = React.useState(
    cookieValue !== undefined
      ? cookieValue === 'true' // convert the string "true"/"false" to an actual boolean
      : initialValue,
  );

  React.useEffect(() => {
    // Always save "true" or "false" as a string in the cookie
    Cookies.set(cookieName, value ? 'true' : 'false');
  }, [cookieName, value]);

  return [value, setValue];
};

export const useButtonCookie = (cookieName, initialValue) => {
  const [value, setValue] = React.useState(
    Cookies.get(cookieName) || initialValue,
  );

  React.useEffect(() => {
    if (typeof value !== 'undefined' && value !== '') {
      Cookies.set(cookieName, value);
    }
  }, [cookieName, value]);

  return [value, setValue];
};
