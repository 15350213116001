import React from 'react';
import PropTypes from 'prop-types';
import URI from 'urijs';
import helper from '../../javascript/frontend/helpers/helper';
import opportunityHelpers from '../../javascript/frontend/helpers/opportunity_helpers';
import vahoy from '../../javascript/vahoy';

class OpportunityIndexTableHeader extends React.Component {
  constructor(props) {
    super(props);

    this.optionalRegionHeader = this.optionalRegionHeader.bind(this);
    this.detailsOrSearchResults = this.detailsOrSearchResults.bind(this);
    this.sortDirectionIcon = this.sortDirectionIcon.bind(this);
    this.sortableLink = this.sortableLink.bind(this);
    this.awardSubmitDate = this.awardSubmitDate.bind(this);
    this.awardDate = this.awardDate.bind(this);
    this.specialReportSubmitDate = this.specialReportSubmitDate.bind(this);
    this.specialReportPublicationDate = this.specialReportPublicationDate.bind(this);
    this.calendarItemStartDate = this.calendarItemStartDate.bind(this);
    this.calendarItemEndDate = this.calendarItemEndDate.bind(this);
    this.eventStartDate = this.eventStartDate.bind(this);
    this.eventEndDate = this.eventEndDate.bind(this);
    this.opportunityDate = this.opportunityDate.bind(this);
    this.updatedAt = this.updatedAt.bind(this);
  }

  trackClicks = () => {
    vahoy.track('Opportunity/OpportunityIndexTableHeader#sort');
  };

  optionalRegionHeader() {
    if (helper.urlParamPresent('region')) {
      return (
        <th>
          <span className="d-none d-sm-block">
            Region
          </span>
        </th>
      );
    }
  }

  detailsOrSearchResults() {
    return helper.urlParamPresent('query') ? 'Results' : 'Details';
  }

  sortDirectionIcon(currentSortCol, iconSortCol, dir) {
    if (currentSortCol == iconSortCol) {
      if (dir == 'desc') {
        return (
          <i className="fa-solid fa-caret-down" />
        );
      }
      if (dir == 'query-relevance') {
        // in this case we've disabled ordering
      } else {
        // if this is the sort column, the default (original) is asc
        return (
          <i className="fa-solid fa-caret-up" />
        );
      }
    }
  }

  sortableLink(column, title) {
    const currentSortCol = helper.urlParamsHash().sort || opportunityHelpers.defaultDateSortColumnForOpportunityArray();
    const currentSortDir = helper.urlParamsHash().direction;

    let newSortDirection;
    newSortDirection = 'desc';

    if (column == currentSortCol) {
      if (currentSortDir == 'desc') {
        // only allow the 'ordering disabled' state, when a query is present
        if (helper.urlParamPresent('query')) {
          newSortDirection = 'query-relevance';
        } else {
          newSortDirection = 'asc';
        }
      } else if (currentSortDir == 'asc') {
        newSortDirection = 'desc';
      } else if (currentSortDir == 'query-relevance') {
        newSortDirection = 'asc';
      }
    }

    const url = URI(window.location.href).query((hash) => {
      // eslint-disable-next-line no-param-reassign
      hash.sort = column;

      // eslint-disable-next-line no-param-reassign
      hash.direction = newSortDirection;

      // eslint-disable-next-line no-param-reassign
      hash.page = null;

      // eslint-disable-next-line no-param-reassign
      hash.start_date = null;
    });

    return (
      <a href={url} onClick={this.trackClicks}>
        {title} {this.sortDirectionIcon(currentSortCol, column, currentSortDir)}
      </a>
    );
  }

  awardSubmitDate(cols) {
    if (_.includes(cols, 'award_submit_date')) {
      return (
        <th className="text-nowrap">
          <span className="d-none d-sm-block">
            {this.sortableLink('award_submit_date', 'Submit by')}
          </span>
        </th>
      );
    }
  }

  awardDate(cols) {
    if (_.includes(cols, 'award_date')) {
      return (
        <th className="text-nowrap">
          <span className="d-none d-sm-block">
            {this.sortableLink('award_date', 'Awarded')}
          </span>
        </th>
      );
    }
  }

  specialReportSubmitDate(cols) {
    if (_.includes(cols, 'special_report_submit_date')) {
      return (
        <th className="text-nowrap">
          <span className="d-none d-sm-block">
            {this.sortableLink('special_report_submit_date', 'Submit by')}
          </span>
        </th>
      );
    }
  }

  specialReportPublicationDate(cols) {
    if (_.includes(cols, 'special_report_publication_date')) {
      return (
        <th className="text-nowrap">
          <span className="d-none d-sm-block">
            {this.sortableLink('special_report_publication_date', 'Published')}
          </span>
        </th>
      );
    }
  }

  calendarItemStartDate(cols) {
    if (_.includes(cols, 'calendar_item_start_date')) {
      return (
        <th className="text-nowrap">
          <span className="d-none d-sm-block">
            {this.sortableLink('calendar_item_start_date', 'Start')}
          </span>
        </th>
      );
    }
  }

  calendarItemEndDate(cols) {
    if (_.includes(cols, 'calendar_item_end_date')) {
      return (
        <th className="text-nowrap">
          <span className="d-none d-sm-block">
            {this.sortableLink('calendar_item_end_date', 'End')}
          </span>
        </th>
      );
    }
  }

  eventStartDate(cols) {
    if (_.includes(cols, 'event_start_date')) {
      return (
        <th className="text-nowrap">
          <span className="d-none d-sm-block">
            {this.sortableLink('event_start_date', 'Start')}
          </span>
        </th>
      );
    }
  }

  eventEndDate(cols) {
    if (_.includes(cols, 'event_end_date')) {
      return (
        <th className="text-nowrap">
          <span className="d-none d-sm-block">
            {this.sortableLink('event_end_date', 'End')}
          </span>
        </th>
      );
    }
  }

  opportunityDate(cols) {
    if (_.includes(cols, 'opportunity_date')) {
      return (
        <th className="text-nowrap">
          <span className="d-none d-sm-block">
            {this.sortableLink('default_sort_col', 'Date')}
          </span>
        </th>
      );
    }
  }

  updatedAt() {
    return (
      <th className="text-nowrap">
        <span className="d-none d-sm-block">
          <small>
            {this.sortableLink('updated_at', 'Updated')}
          </small>
        </span>
      </th>
    );
  }

  createdBy() {
    const { currentUserAdmin } = this.props;

    return currentUserAdmin ? (
      <th className="text-nowrap">
        <span className="d-none d-sm-block">
          <small>
            Created By
          </small>
        </span>
      </th>
    ) : null;
  }

  updatedBy() {
    const { currentUserAdmin } = this.props;

    return currentUserAdmin ? (
      <th className="text-nowrap">
        <span className="d-none d-sm-block">
          <small>
            Updated By
          </small>
        </span>
      </th>
    ) : null;
  }

  render() {
    const cols = opportunityHelpers.sharedDateColumnsForOpportunityArray();

    return (
      <thead>
        <tr>
          <th width="1px">&nbsp;</th>
          <th>Opportunity</th>
          {this.optionalRegionHeader()}
          {this.awardSubmitDate(cols)}
          {this.awardDate(cols)}
          {this.specialReportSubmitDate(cols)}
          {this.specialReportPublicationDate(cols)}
          {this.calendarItemStartDate(cols)}
          {this.calendarItemEndDate(cols)}
          {this.eventStartDate(cols)}
          {this.eventEndDate(cols)}
          {this.opportunityDate(cols)}
          {this.updatedAt()}
          {this.createdBy()}
          {this.updatedBy()}
        </tr>
      </thead>
    );
  }
}

OpportunityIndexTableHeader.propTypes = {
  currentUserAdmin: PropTypes.bool.isRequired,
};

export default OpportunityIndexTableHeader;
