import React from 'react';
import PropTypes from 'prop-types';
import NamePhotoTitle from './name_photo_title';
import ContactInfo from './contact_info';
import Hashtags from '../../hashtags';
import QuoteBioOrPublicPitch from './quote_bio_or_public_pitch';
import ActionButtons from './action_buttons';
import StatusFooter from './status_footer';

function SourceCard({
                      source, updates, smallMode, displayContactAndInfo,
                    }) {
  // Late arriving attributes to be merged in, if any
  if (updates) Object.assign(source, updates.attributes);
  const maxHashtags = smallMode ? 3 : 5;

  return (
    <div className="card h-100 source-card animated faster fadeIn">
      <div className="card-body position-relative">
        <NamePhotoTitle source={source} smallMode={smallMode} />
        {displayContactAndInfo && (
          <ContactInfo source={source} smallMode={smallMode} />
        )}
        <Hashtags
          hashtagTarget={source}
          maxHashtags={maxHashtags}
          smallMode={smallMode}
          title="Covers"
          clickEventName="Sources/SourceCard#clickHashtag"
        />
        {!smallMode && (
          <QuoteBioOrPublicPitch source={source} smallMode={smallMode} />
        )}
        {displayContactAndInfo && (
          <ActionButtons source={source} smallMode={smallMode} />
        )}
      </div>
      <StatusFooter source={source} smallMode={smallMode} />
    </div>
  );
}

SourceCard.propTypes = {
  source: PropTypes.instanceOf(Object),
  updates: PropTypes.instanceOf(Object),
  smallMode: PropTypes.bool,
  displayContactAndInfo: PropTypes.bool,
};

SourceCard.defaultProps = {
  source: undefined,
  updates: undefined,
  smallMode: false,
  displayContactAndInfo: true,
};

export default SourceCard;
