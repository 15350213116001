import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connectInfiniteHits } from 'react-instantsearch-dom';
import { useDebounce } from 'use-lodash-debounce';
import vapi from '../../../javascript/frontend/api/vapi';
import vahoy from '../../../javascript/vahoy';
import NoResultsMessage from '../../algolia_search/no_results_message';
import SearchLoadingCards from '../../source_requests/search/search_loading_cards';
import MediaMatchCard from './media_match_card';
import SourceRequestSkeletonPage from '../../source_requests/index/source_request_skeleton_page';
import useInfiniteScroll from '../../hooks/use_infinite_scroll';
import FullPagePaywall from '../../source_requests/full_page_paywall';

function InfiniteHits({
  hasMore,
  refine,
  hits,
  hiddenSearchString,
  query,
  canPitchMediaMatch,
}) {
  const [lastTriggeredAt, scrollTriggerRef] = useInfiniteScroll({ hasMore });
  const [qwotedRecords, setQwotedRecords] = useState({});
  const [idsToLookup, setIdsToLookup] = useState([]);
  const [asyncError, setAsyncError] = useState();
  const debouncedHits = useDebounce(hits, 700);
  const [showPaywall, setShowPaywall] = useState(false);

  // Trigger "refine" when infinite scrolling demands more hits
  useEffect(() => {
    if (lastTriggeredAt > 0) refine();
  }, [lastTriggeredAt, refine]);

  // Collect missing hits for which we don't have extended data in qwotedRecords
  useEffect(() => {
    const missingIds = debouncedHits
      .filter((h) => qwotedRecords[h.objectID] === undefined)
      .map((h) => h.objectID);
    if (missingIds.length > 0) {
      setIdsToLookup((prevList) => [...prevList, ...missingIds]);
    }
  }, [debouncedHits, qwotedRecords]);

  // Fetch extended data for those missing IDs
  useEffect(() => {
    const fetchRecords = async () => {
      try {
        if (idsToLookup.length > 0) {
          vahoy.track('SourceRequestsSearch#getAlgoliaAttribs', { sourceRequestids: idsToLookup });
          const response = await vapi.getAlgoliaAttribsForIds(idsToLookup);
          if (response.status === 200) {
            const sourceRequestResults = response.data;
            if (sourceRequestResults.data && sourceRequestResults.data.length > 0) {
              const recordsToAdd = {};
              sourceRequestResults.data.forEach((sourceRequest) => {
                recordsToAdd[sourceRequest.id] = sourceRequest;
              });
              setIdsToLookup([]);
              // Merge the newly fetched records into our qwotedRecords state
              setQwotedRecords((prevList) => ({ ...prevList, ...recordsToAdd }));
            }
          }
        }
      } catch (error) {
        setAsyncError(error);
      }
    };
    fetchRecords();
  }, [idsToLookup]);

  const onCardClick = (e) => {
    if (!canPitchMediaMatch) {
      e.preventDefault();
      setShowPaywall(true);
    }
  };

  if (asyncError) throw asyncError;

  // 1) First, build a "final" version of each hit by merging the local updates (qwotedRecords).
  const finalHits = useMemo(() => hits.map((hit) => {
      const updates = qwotedRecords[hit.objectID];
      if (updates && updates.attributes) {
        // Merge the "attributes" object directly into the top level of "hit"
        // so that "hit.reporter" and others are updated
        return { ...hit, ...updates.attributes };
      }
      return hit;
    }), [hits, qwotedRecords]);

  // 2) Deduplicate the merged hits. We prefer "reporter.id" if it exists, else "reporter.first_name_last_initial".
  const uniqueHits = useMemo(() => {
    const seen = new Set();
    const deduped = [];

    for (const hit of finalHits) {
      // Make sure we can safely reference "hit.reporter" or "hit.match.reporter"
      const reporter = hit.reporter
        ? hit.reporter
        : hit.match?.reporter;

      let key = null;
      if (reporter) {
        // If there's a stable reporter.id, use that
        if (reporter.id) {
          key = `reporter-id-${reporter.id}`;
        } else if (reporter.first_name_last_initial) { // Else if there's first_name_last_initial, use that (normalized)
          key = `reporter-initial-${reporter.first_name_last_initial.trim().toLowerCase()}`;
        }
      }

      // If we couldn't determine a key from the reporter, fall back to objectID
      if (!key) {
        key = `object-${hit.objectID}`;
      }

      if (!seen.has(key)) {
        seen.add(key);
        deduped.push(hit);
      }
    }

    return deduped;
  }, [finalHits]);

  return (
    <>
      {showPaywall && (
        <FullPagePaywall
          onClose={() => setShowPaywall(false)}
          isOpen={showPaywall}
        />
      )}
      <NoResultsMessage />
      {uniqueHits && uniqueHits.length > 0 && (
        <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 px-1">
          <SearchLoadingCards />
          {uniqueHits.map((hit) => (
            <div key={hit.objectID} className="col px-2 pb-3 pt-0">
              <MediaMatchCard
                match={hit}
                hiddenSearchString={hiddenSearchString}
                query={query}
                onCardClick={onCardClick}
                canPitchMediaMatch={canPitchMediaMatch}
              />
            </div>
          ))}
        </div>
      )}
      {hasMore && (
        <div ref={scrollTriggerRef} className="row row-cols-1 row-cols-sm-1">
          <SourceRequestSkeletonPage numberOfCards={12} singleRow />
        </div>
      )}
    </>
  );
}

InfiniteHits.propTypes = {
  hasMore: PropTypes.bool,
  refine: PropTypes.func,
  hits: PropTypes.arrayOf(Object),
  query: PropTypes.string,
  hiddenSearchString: PropTypes.string,
  canPitchMediaMatch: PropTypes.bool,
};

InfiniteHits.defaultProps = {
  hasMore: false,
  refine: undefined,
  hits: [],
  query: '',
  hiddenSearchString: '',
  canPitchMediaMatch: true,
};

export default connectInfiniteHits(InfiniteHits);
