import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import EditIcon from './edit_icon';
import vahoy from '../../javascript/vahoy';

class ExpertOn extends React.Component {
  trackClicks = () => {
    vahoy.track('Reporter/ExpertOn#clickHashtagLink');
  };

  renderEditIcon(onClickFn, additionalClasses, optionalText = null) {
    const { isEditable } = this.props;

    return (
      <EditIcon
        isEditable={isEditable}
        optionalText={optionalText}
        onClickFn={onClickFn}
        className={additionalClasses}
      />
    );
  }

  render() {
    const { hashtagTags = [] } = this.props;
    const {
      isLoadingHashtagTags,
      maxNumberOfExpertOnHashtags,
      toggleAddOrEditHashtagTags,
      isEditable,
    } = this.props;
    let displayHashtagTagsComp;
    let title;

    if (!isEditable && (hashtagTags.length === 0 || isLoadingHashtagTags)) {
      displayHashtagTagsComp = null;
      title = null;
    } else {
      const visibleHashtags = hashtagTags.filter((h) => h.attributes.is_visible === 'true'
        && h.attributes.manually_removed === 'false');
      const sortableHashtagTags = visibleHashtags.filter((tp) => tp.attributes.display_order === 0 || tp.attributes.display_order === '0' || tp.attributes.display_order > 0);
      const unsortableHashtagTags = visibleHashtags.filter((tp) => tp.attributes.display_order === 'null' || tp.attributes.display_order === undefined || tp.attributes.display_order === '');

      if (isLoadingHashtagTags) {
        title = null;
      } else {
        title = (
          <h4 className="fw-bold">
            Covers {
            !isLoadingHashtagTags
            && this.renderEditIcon(toggleAddOrEditHashtagTags, 'ms-2 p-0 edit-expertise-btn')
          }
          </h4>
        );
      }

      let displayHashtagTags = _.sortBy(sortableHashtagTags, (tt) => 1 * tt.attributes.display_order)
        .concat(_.sortBy(unsortableHashtagTags, (tt) => 1 * tt.id));
      displayHashtagTags = displayHashtagTags.slice(0, maxNumberOfExpertOnHashtags);

      if (displayHashtagTags.length > 0) {
        displayHashtagTagsComp = (
          <ul className="list-unstyled m-0">
            {displayHashtagTags.map((hashtagTag) => (
              <li className="list-item" key={hashtagTag.id}>
                <a
                  href={hashtagTag.links.frontend}
                  onClick={this.trackClicks}
                >{hashtagTag.attributes.hashtag}
                </a>
              </li>
            ))}
          </ul>
        );
      } else {
        displayHashtagTagsComp = (!isLoadingHashtagTags && isEditable && (
          <ul className="list-unstyled m-0">
            <li className="list-item">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href="#" onClick={toggleAddOrEditHashtagTags}>Click to add your areas of expertise</a>
            </li>
          </ul>
        ));
      }
    }

    return (
      <>
        {title}
        {displayHashtagTagsComp}
      </>
    );
  }
}

ExpertOn.propTypes = {
  isEditable: PropTypes.bool,
  isLoadingHashtagTags: PropTypes.bool,
  maxNumberOfExpertOnHashtags: PropTypes.number.isRequired,
  hashtagTags: PropTypes.arrayOf(Object),
  toggleAddOrEditHashtagTags: PropTypes.func,
};

ExpertOn.defaultProps = {
  isEditable: undefined,
  isLoadingHashtagTags: undefined,
  hashtagTags: undefined,
  toggleAddOrEditHashtagTags: undefined,
};

export default ExpertOn;
