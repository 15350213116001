import React from 'react';
import PropTypes from 'prop-types';
import opportunityHelpers from '../../javascript/frontend/helpers/opportunity_helpers';
import helper from '../../javascript/frontend/helpers/helper';
import dateHelpers from '../../javascript/frontend/helpers/date_helpers';
import vahoy from '../../javascript/vahoy';

class OpportunityIndexTableRow extends React.Component {
  constructor(props) {
    super(props);

    this.conditionalFlag = this.conditionalFlag.bind(this);
    this.optionalRegionCell = this.optionalRegionCell.bind(this);
    this.awardDate = this.awardDate.bind(this);
    this.specialReportPublicationDate = this.specialReportPublicationDate.bind(this);
    this.calendarItemStartDate = this.calendarItemStartDate.bind(this);
    this.calendarItemEndDate = this.calendarItemEndDate.bind(this);
    this.eventStartDate = this.eventStartDate.bind(this);
    this.eventEndDate = this.eventEndDate.bind(this);
    this.submitByDate = this.submitByDate.bind(this);
    this.opportunityDate = this.opportunityDate.bind(this);
    this.updatedAt = this.updatedAt.bind(this);
  }

  trackClicks = (clickType) => {
    vahoy.track(`OpportunityIndexTableRow#clickLink_${clickType}`);
  };

  conditionalFlag() {
    if (!helper.currentUserInternal()) {
      return;
    }

    // eslint-disable-next-line react/destructuring-assignment
    if (this.props.opportunity.attributes.flagged === 'true') {
      return (
        <i className="fa-solid fa-lg fa-flag text-danger pe-2" />
      );
    }
  }

  optionalRegionCell() {
    const { opportunity } = this.props;

    if (helper.urlParamPresent('region')) {
      return (
        <td>
          <span className="d-none d-sm-block">
            {opportunity.attributes.region}
          </span>
        </td>
      );
    }
  }

  awardDate(cols) {
    // eslint-disable-next-line react/destructuring-assignment
    const oa = this.props.opportunity.attributes;
    const fd = dateHelpers.formattedDate(oa.award_date);

    if (_.includes(cols, 'award_date')) {
      return (
        <td>
          <span className="d-none d-sm-block">
            {fd}
          </span>
        </td>
      );
    }
  }

  specialReportPublicationDate(cols) {
    // eslint-disable-next-line react/destructuring-assignment
    const oa = this.props.opportunity.attributes;
    const fd = dateHelpers.formattedDate(oa.special_report_publication_date);

    if (_.includes(cols, 'special_report_publication_date')) {
      return (
        <td>
          <span className="d-none d-sm-block">
            {fd}
          </span>
        </td>
      );
    }
  }

  calendarItemStartDate(cols) {
    // eslint-disable-next-line react/destructuring-assignment
    const oa = this.props.opportunity.attributes;
    const fd = dateHelpers.formattedDate(oa.calendar_item_start_date);

    if (_.includes(cols, 'calendar_item_start_date')) {
      return (
        <td>
          <span className="d-none d-sm-block">
            {fd}
          </span>
        </td>
      );
    }
  }

  calendarItemEndDate(cols) {
    // eslint-disable-next-line react/destructuring-assignment
    const oa = this.props.opportunity.attributes;
    const fd = dateHelpers.formattedDate(oa.calendar_item_end_date);

    if (_.includes(cols, 'calendar_item_end_date')) {
      return (
        <td>
          <span className="text-nowrap-sm-up">
            {fd}
          </span>
        </td>
      );
    }
  }

  eventStartDate(cols) {
    // eslint-disable-next-line react/destructuring-assignment
    const oa = this.props.opportunity.attributes;
    const fd = dateHelpers.formattedDate(oa.event_start_date);

    if (_.includes(cols, 'event_start_date')) {
      return (
        <td>
          <span className="d-none d-sm-block">
            {fd}
          </span>
        </td>
      );
    }
  }

  eventEndDate(cols) {
    // eslint-disable-next-line react/destructuring-assignment
    const oa = this.props.opportunity.attributes;
    const fd = dateHelpers.formattedDate(oa.event_end_date);

    if (_.includes(cols, 'event_end_date')) {
      return (
        <td>
          <span className="text-nowrap-sm-up">
            {fd}
          </span>
        </td>
      );
    }
  }

  submitByDate(cols) {
    // eslint-disable-next-line react/destructuring-assignment
    const oa = this.props.opportunity.attributes;
    const fd = dateHelpers.formattedDate(oa.submit_by_date);

    if (_.includes(cols, 'submit_by_date') || _.includes(
      cols,
      'special_report_submit_date',
    ) || _.includes(cols, 'award_submit_date')) {
      return (
        <td>
          <span className="text-nowrap-sm-up">
            {fd}
          </span>
        </td>
      );
    }
  }

  opportunityDate(cols) {
    // eslint-disable-next-line react/destructuring-assignment
    const oa = this.props.opportunity.attributes;
    const fd = dateHelpers.formattedDate(oa.opportunity_date);

    if (_.includes(cols, 'opportunity_date')) {
      return (
        <td>
          <span className="text-nowrap">
            {fd}
          </span>
        </td>
      );
    }
  }

  updatedAt() {
    // eslint-disable-next-line react/destructuring-assignment
    const oa = this.props.opportunity.attributes;
    const fd = dateHelpers.formattedDate(oa.updated_at, 1);

    return (
      <td>
        <span className="d-none d-sm-block">
          <small className="text-muted">
            {fd}
          </small>
        </span>
      </td>
    );
  }

  createdBy() {
    const { opportunity, currentUserAdmin } = this.props;

    return currentUserAdmin ? (
      <td>
        <span className="d-none d-sm-block">
          <small className="text-muted">
          {opportunity.attributes.created_by_name}
          </small>
        </span>
      </td>
    ) : null;
  }

  updatedBy() {
    const { opportunity, currentUserAdmin } = this.props;

    return currentUserAdmin ? (
      <td>
        <span className="d-none d-sm-block">
          <small className="text-muted">
          {opportunity.attributes.updated_by_name}
          </small>
        </span>
      </td>
    ) : null;
  }

  render() {
    const {
      opportunity: opp,
    } = this.props;
    const oppInPastClass = moment(opp.attributes.opportunity_date) < moment()
      .startOf('day') ? 'text-muted' : null;

    const cols = opportunityHelpers.sharedDateColumnsForOpportunityArray();

    const detailsHighlighted = opportunityHelpers.queryCompressAndHighlight(opp.attributes.details);
    const detailsSanitized = helper.sanitize(_.truncate(
      detailsHighlighted,
      { length: window.TRUNCATE_TEXT },
    ));

    let nameSanitized;
    nameSanitized = helper.sanitize(opp.attributes.name);
    nameSanitized = opportunityHelpers.queryHighlight(nameSanitized);

    /* eslint-disable react/no-danger */
    return (
      <tr className={oppInPastClass}>
        <td>
          <i className={opportunityHelpers.opportunityTypeIconClasses(opp.attributes.opportunity_type)} />
        </td>
        <td>
          {this.conditionalFlag()}
          { /* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <a
            href={opp.links.frontend}
            onClick={() => this.trackClicks('opportunity_details')}
            dangerouslySetInnerHTML={{ __html: nameSanitized }}
          />
          <div className="mb-2" />
          <p className="d-none d-sm-block" dangerouslySetInnerHTML={{ __html: detailsSanitized }} />
        </td>
        {this.optionalRegionCell()}
        {this.submitByDate(cols)}
        {this.awardDate(cols)}
        {this.specialReportPublicationDate(cols)}
        {this.calendarItemStartDate(cols)}
        {this.calendarItemEndDate(cols)}
        {this.eventStartDate(cols)}
        {this.eventEndDate(cols)}
        {this.opportunityDate(cols)}
        {this.updatedAt()}
        {this.createdBy()}
        {this.updatedBy()}
      </tr>
    );
    /* eslint-enable react/no-danger */
  }
}

OpportunityIndexTableRow.propTypes = {
  opportunity: PropTypes.instanceOf(Object).isRequired,
  currentUserAdmin: PropTypes.bool.isRequired,
};

OpportunityIndexTableRow.defaultProps = {};

export default OpportunityIndexTableRow;
