import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import vahoy from '../../../javascript/vahoy';
import vlog from '../../../javascript/vlog';

function CreateRequestButton({ publicPitch, currentUserSlug }) {
  const [loading, setLoading] = useState(false);
  const [passedHealthCheck, setPassedHealthCheck] = useState(false);

  useEffect(() => {
    const v2HealthCheck = async () => {
      const passed = await window.v2HealthCheck();
      if (passed) {
        setPassedHealthCheck(true);
      } else {
        vlog.debug('Component not ready: V2 health check did not pass.');
      }
    };

    v2HealthCheck();
  }, []);

  if (!passedHealthCheck) return null; // comment to test locally

  // Helper function to combine pitch text fields
  const getCombinedText = (pitch) => [
      pitch.publicPitchTitle || '',
      pitch.publicPitchLede || '',
      pitch.publicPitchPreamble || '',
      pitch.publicPitchText || '',
    ].filter(Boolean).join(' ');

  // Helper function to call the text transformation API
  const fetchTextTransformation = async (token, text) => {
    const response = await fetch(`${window.V2_URL}/match_feed/api/text_transformation`, {
      method: 'POST',
      headers: {
        apikey: window.SUPABASE_ANON_KEY,
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        transformation_type: 'pitch_to_request',
        text,
      }),
    });

    if (!response.ok) throw new Error('Failed to analyze text');

    const data = await response.json();
    return data?.result;
  };

  // Helper function to track and navigate
  const navigateToSourceRequest = (name, details, text = '') => {
    let url = `/users/${currentUserSlug}/reporter_requests/new`;
    const params = new URLSearchParams();

    if (name) params.append('name', name);
    if (details) params.append('details', details);

    if (params.toString()) url += `?${params.toString()}`;

    vahoy.track('PublicPitch#createSourceRequest', {
      text,
      object_gid: `gid://vested/PublicPitch/${publicPitch.id}`,
    });

    window.location.href = url;
  };

  const handleCreateSourceRequest = async () => {
    const publicPitchTitle = publicPitch.publicPitchTitle === 'null' ? '' : publicPitch.publicPitchTitle;
    const publicPitchText = publicPitch.publicPitchText === 'null' ? '' : publicPitch.publicPitchText;

    try {
      setLoading(true);

      const combinedText = getCombinedText(publicPitch);
      const token = await window.supabaseToken();
      const result = await fetchTextTransformation(token, combinedText);
      const name = result?.title || publicPitchTitle;
      const details = result?.details || publicPitchText;

      navigateToSourceRequest(name, details, combinedText);
    } catch (error) {
      vlog.error('Error creating source request:', error);
      navigateToSourceRequest(publicPitchTitle, publicPitchText);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mt-2 d-grid">
      <button
        type="button"
        className="btn btn-primary"
        onClick={handleCreateSourceRequest}
        disabled={loading}
      >
        {loading ? (
          <i className="public-pitch-contact-link-icon fa-solid fa-spinner fa-spin align-middle" />
        ) : (
          <i className="public-pitch-contact-link-icon fa-solid fa-plus align-middle" />
        )}{' '}
        Find More Experts
      </button>
    </div>
  );
}

CreateRequestButton.propTypes = {
  publicPitch: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    publicPitchTitle: PropTypes.string.isRequired,
    publicPitchLede: PropTypes.string,
    publicPitchPreamble: PropTypes.string,
    publicPitchText: PropTypes.string.isRequired,
  }).isRequired,
  currentUserSlug: PropTypes.string.isRequired,
};

export default CreateRequestButton;
