import React, { useState } from 'react';
import PropTypes from 'prop-types';

import TabbedNavigation from '../tabbed_navigation';

function Navigation({ view, onTabChange, onlyTabs }) {
  const [activeTab, setActiveTab] = useState(view.toUpperCase());

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    onTabChange(tab);
  };

  const tabs = [
    {
      id: 'SOURCES',
      display: 'Experts',
      href: '/sources',
    },
    {
      id: 'PRODUCTS',
      display: 'Products',
      href: '/products',
    },
    {
      id: 'PRS',
      display: 'PRs',
      href: '/pr_users',
    },
    {
      id: 'COMPANIES',
      display: 'Companies',
      href: '/companies',
      loggedInOnly: true,
    },
    {
      id: 'PITCHES',
      display: 'Story Ideas',
      href: '/story_ideas',
    },
    {
      id: 'PRESSRELEASES',
      display: 'Press Releases',
      href: '/press_releases',
    },
    {
      id: 'STORYBUILDER',
      display: 'All',
      href: '/story_builder',
    },
    {
      id: 'SAVED_ITEMS',
      display: 'Saved Items',
      href: '/products?view=saved_products',
      loggedInOnly: true,
      icon: 'bookmark',
      color: 'dark',
      submenu: [
        {
          id: 'FAV_SOURCES',
          display: 'Saved Experts',
          href: '/sources?view=fav_sources',
          loggedInOnly: true,
        },
        {
          id: 'SAVED_PRODUCTS',
          display: 'Saved Products',
          href: '/products?view=saved_products',
          loggedInOnly: true,
        },
        {
          id: 'FAV_PRS',
          display: 'Saved PRs',
          href: '/pr_users?view=fav_prs',
          loggedInOnly: true,
        },
      ],
    },
  ];

  const filteredTabs = tabs.filter((tab) => onlyTabs.length === 0 || onlyTabs.includes(tab.id));

  return (
    <TabbedNavigation
      tabs={filteredTabs}
      activeTab={activeTab}
      onTabChange={handleTabChange}
    />
  );
}

Navigation.propTypes = {
  view: PropTypes.string.isRequired,
  onlyTabs: PropTypes.array,
  onTabChange: PropTypes.func,
};

Navigation.defaultProps = {
  onTabChange: () => {},
  onlyTabs: [],
};

export default Navigation;
