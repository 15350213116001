/* eslint-disable no-nested-ternary */

import React from 'react';
import { Panel, RefinementList } from 'react-instantsearch-dom';

import helper from '../../../../javascript/frontend/helpers/helper';

function SiteFilterPanel() {
  return (
    <div className="card mb-3">
      <div className="card-body">
        <Panel header="filter by site">
          <RefinementList
            attribute="site_name"
            limit={6}
            operator="or"
            showMore={true}
            showMoreLimit={15}
            searchable={true}
            transformItems={(items) => items
                .map((item) => ({
                  ...item,
                  label: helper.beautifulTruncateStr(item.label, 35),
                }))
                .sort((a, b) => (a.label === 'Qwoted' ? -1 : b.label === 'Qwoted' ? 1 : 0))}
          />
        </Panel>
      </div>
    </div>
  );
}

export default SiteFilterPanel;
