import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../../avatar/avatar';
import Hashtags from '../../hashtags';

function MediaMatchCard({
                                   sourceRequest, query, canPitchConnectionOpps, onCardClick,
                                 }) {
  const linkUrl = canPitchConnectionOpps ? `${sourceRequest.source_request_path}?query=${query}` : '';
  return (
    <div className="d-flex flex-column overflow-hidden">
      <div className="bg-light p-1 rounded">
        <p className="text-primary text-center my-0">MEET THE MEDIA</p>
      </div>
      <div className="d-flex align-items-center p-3">
        <Avatar
          avatarUrl={sourceRequest.reporter?.avatar_url}
          sizeClass="avatar-md border border-primary"
          avatarLetters={sourceRequest.reporter?.initials}
        />
        <div>
          <h3 className="ms-3 mb-1 mt-1">{sourceRequest.reporter?.first_name_last_initial}</h3>
          <h3 className="ms-3 mb-1 mt-0">covers this</h3>
        </div>
      </div>
      <div className="text-center py-3">
        <a
          className="btn btn-primary btn-lg w-75 px-1"
          href={linkUrl}
          onClick={onCardClick}
        >
          <i className="fas fa-message me-1" /> Start a conversation
        </a>
      </div>
      <div className="d-flex flex-wrap justify-content-center px-3 py-1 font-size-px text-black">
        <Hashtags
          hashtagTarget={sourceRequest}
          maxHashtags={3}
          smallMode={false}
          clickEventName="SourceRequests/SourceRequestCard#clickHashtag"
        />
      </div>
      <div className="p-3">
        {sourceRequest.reporter?.active && (
          <span className="fw-bold text-black"><i
            style={{ color: '#74b53f' }}
            className="fa fa-circle rounded rounded-circle border border-3"
          /> Active</span>
        )}
        <p className="mb-1">Media Outlets:
          <img
            alt={sourceRequest.publication.name}
            src={sourceRequest.publication.logo_url}
            className="ms-2 float-end"
            style={{
              maxHeight: '35px',
              maxWidth: '100px',
            }}
          />
        </p>
      </div>
    </div>
  );
}

MediaMatchCard.propTypes = {
  sourceRequest: PropTypes.instanceOf(Object),
  query: PropTypes.string,
  canPitchConnectionOpps: PropTypes.bool,
  onCardClick: PropTypes.func,
};

MediaMatchCard.defaultProps = {
  sourceRequest: undefined,
  query: undefined,
  canPitchConnectionOpps: true,
  onCardClick: undefined,
};

export default MediaMatchCard;
