import React from 'react';
import PropTypes from 'prop-types';

function RequestTypeSelect({ requestType, setRequestType, handleLogistics }) {
  const types = ['experts', 'guests', 'speakers', 'products', 'man_on_the_street'];

  const capitalize = (string) => string[0].toUpperCase() + string.slice(1);

  const humanizeType = (type) => {
    const array = type.split('_');
    return array.map((s) => capitalize(s)).join(' ');
  };

  const updateRequestType = (selectedRequestType) => {
    setRequestType(selectedRequestType);
    handleLogistics(true, false);
  };

  const renderMenuItem = (type) => {
    const selected = requestType === type;

    return (
      <button
        key={type}
        type="button"
        className={`btn btn-link d-block d-md-inline text-decoration-none mx-0 mx-md-2 border-bottom-0 pb-0 ${selected ? 'selected' : ''}`}
        onClick={() => updateRequestType(type)}
      >
        <h3
          className="px-2"
        >
          {humanizeType(type)}
        </h3>
        {selected
          && <div className="nav-item-underline w-100 mt-1" />}
      </button>
    );
  };

  return (
    <nav
      className="select-request-types-nav d-md-flex flex-row flex-wrap justify-content-between px-0 px-md-4 pt-2 mx-0"
    >
      {types.map((type) => renderMenuItem(type))}
    </nav>
  );
}

RequestTypeSelect.propTypes = {
  requestType: PropTypes.string,
  setRequestType: PropTypes.func,
  handleLogistics: PropTypes.func.isRequired,
};

RequestTypeSelect.defaultProps = {
  requestType: undefined,
  setRequestType: undefined,
};

export default RequestTypeSelect;
