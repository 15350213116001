import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Datetimepicker from '../datetimepicker';

function PressReleaseDateTimePicker({
  attr,
  divClassName,
  flatpickrClassName,
  formName,
  includeTime,
  inputGroupClassName,
  labelClassName,
  max,
  min,
  value,
  paid,
}) {
  const [releaseOption, setReleaseOption] = useState(value ? 'specific' : 'immediate');

  const [dateValue, setDateValue] = useState(value);

  useEffect(() => {
    if (releaseOption === 'immediate') {
      setDateValue('');
    }
  }, [releaseOption]);

  const handleDatetimeChange = (selectedDates) => {
    if (selectedDates && selectedDates.length > 0) {
      setReleaseOption('specific');

      const isoDate = selectedDates[0].toISOString();
      setDateValue(isoDate);
    }
  };

  const handleDateFieldFocus = () => {
    setReleaseOption('specific');
  };

  return (
    <div className="row pb-3 border-top pt-3">
      <div className="col-12 col-md-2 fw-bold fs-4">
        Release Date/time
      </div>
      <div className="col-12 col-md-8">
        <div className="card" style={{ width: '97%' }}>
          <div className="card-body">
            <div className="mb-3">
              <h6 className="mb-3 fw-bold mt-2">When would you like this distributed?</h6>

              <div className="d-flex mb-3 align-items-start">
                <div className="me-3 mt-1">
                  <input
                    id="immediate-release"
                    type="radio"
                    name="release_option"
                    className="form-check-input"
                    checked={releaseOption === 'immediate'}
                    onChange={() => setReleaseOption('immediate')}
                  />
                </div>
                <div className="flex-grow-1">
                  <label htmlFor="immediate-release" className="fw-bold">
                    For Immediate Release
                  </label>
                  { paid && (
                    <p className="text-muted small">
                      Please note: It can take 24-48 hours to publish across paid distribution networks.
                    </p>
                  )}
                </div>
              </div>

              <div className="d-flex mb-3 align-items-start w-50">
                <div className="me-3 mt-1">
                  <input
                    id="specific-date"
                    type="radio"
                    name="release_option"
                    className="form-check-input"
                    checked={releaseOption === 'specific'}
                    onChange={() => setReleaseOption('specific')}
                  />
                </div>
                <div className="flex-grow-1">
                  <label htmlFor="specific-date" className="fw-bold">
                    On a specific date and time
                    <p> Times should be US Eastern Standard Time (EST)</p>
                  </label>

                  <div className="mt-3 row">
                    <div className="col-12">
                      { /* eslint-disable jsx-a11y/click-events-have-key-events */ }
                      { /* eslint-disable jsx-a11y/no-static-element-interactions */ }
                      { releaseOption === 'specific' && (
                        <div onClick={handleDateFieldFocus}>
                          <Datetimepicker
                            attr={attr}
                            attr_humanized=""
                            div_className={divClassName}
                            flatpickr_className={flatpickrClassName}
                            form_name={formName}
                            include_time={includeTime}
                            input_group_className={inputGroupClassName}
                            label_className={labelClassName}
                            max={max}
                            min={min}
                            onChange={handleDatetimeChange}
                            value={releaseOption === 'immediate' ? '' : dateValue}
                            forceEST={true}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

PressReleaseDateTimePicker.propTypes = {
  attr: PropTypes.string,
  divClassName: PropTypes.string,
  flatpickrClassName: PropTypes.string,
  formName: PropTypes.string,
  includeTime: PropTypes.bool,
  inputGroupClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  max: PropTypes.instanceOf(Object),
  min: PropTypes.instanceOf(Object),
  value: PropTypes.string,
  paid: PropTypes.bool,
};

PressReleaseDateTimePicker.defaultProps = {
  attr: 'publish_on',
  divClassName: '',
  flatpickrClassName: '',
  formName: 'press_release[publish_on]',
  includeTime: true,
  inputGroupClassName: '',
  labelClassName: '',
  max: undefined,
  min: undefined,
  value: undefined,
  paid: false,
};

export default PressReleaseDateTimePicker;
