import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'reactstrap';
import useFavorite from './hooks/use_favorite';

function FollowButton({
  favorited: initiallyFavorited,
  favoriteId: initialFavoriteId,
  favoriteableId,
  favoriteableType,
  ahoyObjectGid,
  page,
  size,
  showTooltip,
  tooltipText,
  tooltipId,
  tooltipOpen,
  themeClass,
}) {
  const [tooltipIsOpen, setTooltipIsOpen] = useState(tooltipOpen);
  const { isFavorited: isFollowed, toggleFavoritedStatus: toggleFollowedStatus } = useFavorite({
    favoriteableId,
    favoriteableType,
    initiallyFavorited,
    initialFavoriteId,
    ahoyObjectGid,
    page,
  });

  const toggleTooltip = () => setTooltipIsOpen(!tooltipIsOpen);

  const data = {
    follow: {
      id: tooltipId ? `tooltip-${tooltipId}` : null,
      specificClasses: `text-${themeClass} border-${themeClass} shadow-sm`,
      title: 'Follow',
      text: 'Follow',
      specificIconClasses: `text-${themeClass}`,
    },
    unfollow: {
      id: tooltipId ? `tooltip-${tooltipId}` : null,
      specificClasses: `bg-${themeClass} text-white`,
      title: 'Unfollow',
      text: 'Following',
      specificIconClasses: 'text-white',
    },
  };
  const buttonInfo = isFollowed ? data.unfollow : data.follow;

  return (
    <>
      <button
        id={buttonInfo.id}
        type="button"
        onClick={toggleFollowedStatus}
        style={{
          verticalAlign: 'middle',
          width: page === 'Shared Work Card' ? '100%' : 'auto',
        }}
        className={`btn btn-${size} btn-outline border rounded rounded-lg px-3 animated faster fadeIn ${buttonInfo.specificClasses}`}
      >
        <i className={`${buttonInfo.specificIconClasses} fa-solid fa-bell me-2 fa-lg`} title={buttonInfo.title} />
        {buttonInfo.text}
      </button>
      {!isFollowed && showTooltip && document.getElementById(buttonInfo.id) && (
        <Tooltip isOpen={tooltipIsOpen} target={buttonInfo.id} toggle={toggleTooltip}>
          {tooltipText}
        </Tooltip>
      )}
    </>
  );
}

FollowButton.propTypes = {
  favoriteableId: PropTypes.number.isRequired, // Id of favoriting target
  favoriteableType: PropTypes.string.isRequired, // Object type of favoriting target (ex: 'PrUser')
  favoriteId: PropTypes.number, // Primary key of favorite record in the favorites table
  favorited: PropTypes.bool.isRequired, // Whether or not the target is currently favorited
  ahoyObjectGid: PropTypes.string.isRequired, // Global id for user being favorited
  size: PropTypes.string,
  themeClass: PropTypes.string,
  page: PropTypes.string,
  showTooltip: PropTypes.bool,
  tooltipId: PropTypes.number,
  tooltipText: PropTypes.string,
  tooltipOpen: PropTypes.bool,
};

FollowButton.defaultProps = {
  favoriteId: undefined,
  size: 'sm',
  themeClass: 'primary',
  page: '',
  showTooltip: false,
  tooltipId: undefined,
  tooltipText: undefined,
  tooltipOpen: false,
};

export default FollowButton;
