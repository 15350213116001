import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Avatar from '../avatar/avatar';
import ChartJS from '../chart_js';
import LoadingImage from '../loading_image';
import vapi from '../../javascript/frontend/api/vapi';
import dateHelpers from '../../javascript/frontend/helpers/date_helpers';
import helper from '../../javascript/frontend/helpers/helper';
import vahoy from '../../javascript/vahoy';

class ContentTabs extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  componentDidMount() {
    this.loadPopularArticles();
    this.loadSourceRequests();
    this.loadStats();
  }

  // eslint-disable-next-line react/sort-comp
  loadRecentArticles() {
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.isLoadingRecentArticles) {
      return;
    }

    const { reporterId } = this.props;
    const params = {};
    params['filter[reporter_id]'] = reporterId;
    params['filter[include_hidden]'] = 0;
    params.sort = '-published_at';
    params['page[limit]'] = 10;
    this.setState({ isLoadingRecentArticles: true });

    vapi.getArticles(params)
      .then((response) => {
        const responseData = response.data;
        const states = { isLoadingRecentArticles: false };

        states.recentArticles = responseData.data;
        states.articlesCount = (responseData.meta || {}).record_count;
        this.setState(states);
      })
      .catch(() => {
        this.setState({ isLoadingRecentArticles: false });
      });
  }

  loadPopularArticles() {
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.isLoadingPopularArticles) {
      return;
    }
    const { reporterId } = this.props;
    const params = {};
    params['filter[reporter_id]'] = reporterId;
    params['filter[include_hidden]'] = 0;
    params['page[limit]'] = 5;
    this.setState({ isLoadingPopularArticles: true });

    vapi.getArticles(params)
      .then((response) => {
        const responseData = response.data;
        const states = { isLoadingPopularArticles: false };

        states.popularArticles = responseData.data;
        this.setState(states);

        if ((states.popularArticles || []).length === 0) {
          this.showTab('source_requests');
        }
      })
      .catch(() => {
        this.setState({ isLoadingPopularArticles: false });
      });
  }

  loadSourceRequests(reporter) {
    // eslint-disable-next-line react/destructuring-assignment
    const ct = reporter || this.props.reporter || {};
    const { userId } = this.props;
    const authorId = (ct.attributes || {}).managing_reporter_user_id;

    if (authorId && userId) {
      const params = {
        page: 1,
        per_page: 4,
        reporter_user: authorId,
      };

      this.setState({ isLoadingSourceRequests: true });
      vapi.getSourceRequests(params)
        .then((response) => {
          const responseData = response.data;
          const states = { isLoadingSourceRequests: false };
          const sourceRequests = responseData.data || [];

          states.sourceRequests = sourceRequests;
          states.sourceRequestsCount = (responseData.meta || {}).total;
          this.setState(states);
        })
        .catch(() => {
          this.setState({ isLoadingSourceRequests: false });
        });
    }
  }

  loadStats() {
    const { reporterId } = this.props;

    this.setState({
      isLoadingStats: true,
      articlePublicationsOrderedByArticleCount: {},
    });

    axios.get(`/api/internal/reporters/${reporterId}/article_publications_ordered_by_article_count`)
      .then(({ data }) => {
        const states = {
          isLoadingStats: false,
          articlePublicationsOrderedByArticleCount: data,
        };
        this.setState(states);
      })
      .catch(() => {
        this.setState({ isLoadingStats: false });
      });
  }

  showTab = (tab) => {
    this.setState({ contentTab: tab });
    const {
      recentArticles,
      sourceRequests,
      articlePublicationsOrderedByArticleCount,
    } = this.state;

    if (tab === 'recent_articles') {
      if (!recentArticles) {
        this.loadRecentArticles();
      }

      vahoy.track('Reporter/ContentTabs#showRecentArticles');
    } else if (tab === 'source_requests') {
      if (!sourceRequests) {
        this.loadSourceRequests();
      }

      vahoy.track('Reporter/ContentTabs#showSourceRequests');
    } else if (tab === 'stats') {
      if (!articlePublicationsOrderedByArticleCount) {
        this.loadStats();
      }

      vahoy.track('Reporter/ContentTabs#showStats');
    } else {
      vahoy.track('Reporter/ContentTabs#showPopularArticles');
    }
  };

  trackClicks = (clickType) => {
    vahoy.track(`Reporter/ContentTabs#clickLink_${clickType}`);
  };

  renderTabContent() {
    const { contentTab } = this.state;
    let content;

    if (!contentTab || contentTab == 'popular_articles') {
      content = this.renderPopularArticles();
    } else if (contentTab == 'recent_articles') {
      content = this.renderRecentArticles();
    } else if (contentTab == 'source_requests') {
      content = this.renderSourceRequests();
    } else if (contentTab == 'stats') {
      content = this.renderStats();
    }

    return content;
  }

  renderPopularArticles() {
    const { popularArticles: articles = [] } = this.state;
    const { isLoadingPopularArticles } = this.state;

    if (isLoadingPopularArticles) {
      return (
        <div className="bg-white shadow-sm p-3 text-center">
          <LoadingImage />
        </div>
      );
    }

    if (articles.length > 0) {
      return (
        <div className="mb-3 articles">
          <ul className="list-unstyled m-0">
            {articles.map((article) => this.renderArticle(article))}
          </ul>
        </div>
      );
    }
  }

  renderRecentArticles() {
    const {
      canViewArticles,
      currentUser,
      reporter,
      reporterId,
    } = this.props;
    const { recentArticles = [] } = this.state;
    const reporterAttrs = (reporter || {}).attributes;

    const {
      articlesCount,
      isLoadingRecentArticles,
    } = this.state;

    if (isLoadingRecentArticles) {
      return (
        <div className="bg-white shadow-sm p-3 text-center">
          <LoadingImage />
        </div>
      );
    }

    const seeAllLink = (articlesCount > 0) && (
      <div className="text-end">
        <a href={`/articles?reporter=${reporterId}`} onClick={() => this.trackClicks('seeAllArticles')}>See all
          ({articlesCount})
        </a>
      </div>
    );

    const missingArticleBox = ((reporter
      && currentUser
      && currentUser.id === reporterAttrs.managing_reporter_user_id) && (
      <li className="bg-white shadow-sm p-3" style={{ marginTop: '1px' }}>
        <div className="alert alert-primary" role="alert">
          Not seeing one of your recent work? Please let us know at&nbsp;
          <a
            href={`mailto:${window.SUPPORT_EMAIL}?Subject=Missing%20article`}
            onClick={() => this.trackClicks('emailSupport')}
            target="_top"
          >
            {window.SUPPORT_EMAIL}
          </a>
          <br />
          <small>
            Our automated system generally adds newly-published articles within 2 to 3 weeks.
          </small>
        </div>
      </li>
    ));

    if (recentArticles.length > 0) {
      return (
        <div className="mb-3 articles">
          <ul className="list-unstyled m-0">
            {missingArticleBox}
            {recentArticles.map((article) => this.renderArticle(article))}
          </ul>

          {canViewArticles
            && (
              <div className="bg-white shadow-sm p-3" style={{ marginTop: '1px' }}>
                {seeAllLink}
              </div>
            )}
        </div>
      );
    }
  }

  renderStats() {
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.isLoadingStats) {
      return (
        <div className="bg-white shadow-sm p-3 text-center">
          <LoadingImage />
        </div>
      );
    }

    const { articlePublicationsOrderedByArticleCount: pubs = {} } = this.state;
    if (Object.keys(pubs).length > 0) {
      const labels = Object.keys(pubs);
      const data = Object.values(pubs);

      const config = {
        type: 'pie',
        data: {
          labels,
          datasets: [
            {
              data,
            },
          ],
        },
        options: {
          aspectRatio: 1,
          legend: {
            align: 'end',
            display: true,
          },
          title: {
            display: true,
            text: 'Articles per publication',
          },
        },
      };
      return (
        <div className="bg-white shadow-sm p-3 text-center" style={{ marginTop: '1px' }}>
          <ChartJS config={config} />
        </div>
      );
    }
  }

  renderArticle(article) {
    let presentation;

    if (article.attributes.image_url) {
      presentation = (
        <div className="pe-3" style={{ width: '130px' }}>
          <img src={article.attributes.image_url} alt="" className="img-fluid" />
        </div>
      );
    }

    return (
      <li className="bg-white shadow-sm p-3" key={article.id} style={{ marginTop: '1px' }}>
        <div className="d-md-flex">
          {presentation}
          <div style={{ flex: 1 }}>
            <a
              className="fw-bold d-block mb-0"
              href={article.attributes.article_url}
              onClick={() => this.trackClicks('article')}
            >
              {article.attributes.title}
            </a>
            <div className="font-size-14px font-family-lato fw-bold mb-3">
              {dateHelpers.formattedDate(article.attributes.published_at)}&mdash;{article.attributes.publication_name}
            </div>
            <div className="p">
              {article.attributes.content_excerpt}
            </div>
          </div>
        </div>
      </li>
    );
  }

  renderSourceRequests() {
    const {
      sourceRequests = [],
      sourceRequestsCount,
      isLoadingSourceRequests,
    } = this.state;
    const {
      currentEmployments,
      canViewSourceRequests,
      publications = [],
      reporter,
      currentUser,
    } = this.props;
    const reporterAttrs = (reporter || {}).attributes;
    const avatar = reporter.avatar || {};
    const allSourceRequestsPath = (window.CURRENT_USER_JSON
      && window.CURRENT_USER_JSON.id === reporterAttrs.managing_reporter_user_id)
      ? `/users/${window.CURRENT_USER_JSON.attributes.slug}/reporter_requests`
      : `/source_requests?reporter_user=${reporterAttrs.managing_reporter_user_id}`;

    if (isLoadingSourceRequests) {
      return (
        <div className="bg-white shadow-sm p-3 text-center" style={{ marginTop: '1px' }}>
          <LoadingImage />
        </div>
      );
    }

    if (canViewSourceRequests) {
      if (sourceRequests.length > 0) {
        const seeAllLink = (sourceRequestsCount > 0) && (
          <li className="bg-white shadow-sm p-3 text-end" style={{ marginTop: '1px' }}>
            <a
              href={allSourceRequestsPath}
              onClick={() => this.trackClicks('seeAllSourceRequests')}
            >See all
              ({sourceRequestsCount})
            </a>
          </li>
        );

        return (
          <ul className="list-unstyled m-0">
            {sourceRequests.map((sourceRequest) => {
              const avatarUrl = (avatar.attributes || {}).cloudinary_url;
              const identifierHumanized = _.trim(reporterAttrs.identifier_humanized);
              const initials = reporterAttrs.initials
                || helper.getInitialLettersFromString(identifierHumanized);
              const pitchable = sourceRequest.attributes.want_pitches
                && currentUser
                && currentUser.attributes.user_type_humanized == 'source';
              const avatarComp = (
                <Avatar
                  avatarUrl={avatarUrl}
                  sizeClass="avatar-sm"
                  avatarLetters={initials}
                />
              );
              const sourceRequestPublication = publications
                .find((pub) => pub.id === sourceRequest.attributes.publication_id);
              let reporterEmployment;
              let titleString;
              let employment;

              if (sourceRequestPublication) {
                reporterEmployment = currentEmployments
                  .find((ce) => ce.attributes.publication_id === sourceRequestPublication.id);

                if (reporterEmployment) {
                  titleString = [reporterEmployment.attributes.title, sourceRequestPublication.attributes.name]
                    .filter((compact) => compact)
                    .join(' at ');
                  employment = (
                    <div className="d-inline text-muted">
                      {titleString}
                    </div>
                  );
                }
              }

              return (
                <li className="bg-white shadow-sm" key={sourceRequest.id} style={{ marginTop: '1px' }}>
                  <div className="p-3 d-flex flex-row">
                    {avatarComp}
                    <div className="ps-3" style={{ flex: 1 }}>
                      <div>
                        <span className="badge bg-primary float-end">Request</span>
                        <h5 className="d-inline mb-0 fw-bold">{identifierHumanized}&nbsp;</h5>
                        {employment}
                      </div>
                    </div>
                  </div>
                  <div className="p-3 border-light border-top">
                    <h4>
                      <a
                        href={`/source_requests/${sourceRequest.attributes.slug}`}
                        onClick={() => this.trackClicks('sourceRequest')}
                      >{sourceRequest.attributes.name}
                      </a>
                    </h4>
                    <div className="mb-3">{sourceRequest.attributes.details}</div>
                    {pitchable && (
                      <div className="float-start">
                        <a
                          className="btn btn-outline-primary ps-5 pe-5 text-primary"
                          href={`/source_requests/${sourceRequest.attributes.slug}`}
                          onClick={() => this.trackClicks('pitchButton')}
                        >Pitch
                        </a>
                      </div>
                    )}
                    <div className="float-end text-end mt-3 text-muted">
                      {dateHelpers.timeAgoInWords(sourceRequest.attributes.created_at)}
                    </div>
                    <div className="clearfix" />
                  </div>
                </li>
              );
            })}
            {seeAllLink}
          </ul>
        );
      }
    } else if (currentUser) {
      return (
        <div className="bg-white shadow-sm p-3 text-center" style={{ marginTop: '1px' }}>
          <div className="p-3">
            <em>
              Only accredited PRs and Experts can view reporter requests. Other reporters cannot.
            </em>
          </div>
        </div>
      );
    } else {
      return (
        <div className="bg-white shadow-sm p-3 text-center" style={{ marginTop: '1px' }}>
          <div className="p-3">
            If the reporter has submitted a request and you are permitted
            to see it, you will find it here.
          </div>
          <div className="p-3">
            <strong>
              <a href="/users/sign_up" className="text-primary" onClick={() => this.trackClicks('signup')}>Sign up
                for a
                free account to view.
              </a>
            </strong>
          </div>
          <div className="p-3 text-secondary">
            <em>NOTE: Only accredited PRs and Experts can view reporter requests. Other reporters cannot.</em>
          </div>
        </div>
      );
    }
  }

  render() {
    const {
      className = '',
      reporter = {},
      canViewStats,
      canViewSourceRequests,
    } = this.props;
    const {
      contentTab,
      popularArticles,
      articlePublicationsOrderedByArticleCount,
      sourceRequests,
    } = this.state;

    let sourceRequestsTab;
    let popularArticlesTab;
    let recentArticlesTab;
    let statsTab;

    if ((reporter.attributes || {}).managing_reporter_user_id
      && ((sourceRequests && sourceRequests.length > 0) || !canViewSourceRequests)) {
      sourceRequestsTab = (
        <li className="nav-item">
          <span
            className={`h4 m-0 cursor-pointer nav-link ${contentTab === 'source_requests' ? 'active fw-bold' : 'text-black-50'}`}
            role="button"
            tabIndex="-1"
            onClick={() => this.showTab('source_requests')}
            onKeyUp={(evt) => evt.keyCode === 13 && this.showTab('source_requests')}
          >
            Requests
          </span>
        </li>
      );
    }

    if (!popularArticles || popularArticles.length > 0) {
      popularArticlesTab = (
        <li className="nav-item">
          <span
            className={`h4 m-0 cursor-pointer nav-link ${!contentTab || contentTab === 'popular_articles' ? 'active fw-bold' : 'text-black-50'}`}
            role="button"
            tabIndex="-1"
            onClick={() => this.showTab('popular_articles')}
            onKeyUp={(evt) => evt.keyCode == 13 && this.showTab('popular_articles')}
          >
            Most Popular
          </span>
        </li>
      );

      recentArticlesTab = (
        <li className="nav-item">
          <span
            className={`h4 m-0 cursor-pointer nav-link ${contentTab === 'recent_articles' ? 'active fw-bold' : 'text-black-50'}`}
            role="button"
            tabIndex="-1"
            onClick={() => this.showTab('recent_articles')}
            onKeyUp={(evt) => evt.keyCode == 13 && this.showTab('recent_articles')}
          >
            Recent Work
          </span>
        </li>
      );
    }

    if (!articlePublicationsOrderedByArticleCount || Object.keys(articlePublicationsOrderedByArticleCount).length > 0) {
      statsTab = (canViewStats && (
        <li className="nav-item">
          <span
            className={`h4 m-0 cursor-pointer nav-link ${contentTab === 'stats' ? 'active fw-bold' : 'text-black-50'}`}
            role="button"
            tabIndex="-1"
            onClick={() => this.showTab('stats')}
            onKeyUp={(evt) => evt.keyCode === 13 && this.showTab('stats')}
          >
            Stats
          </span>
        </li>
      ));
    }

    // Avoid showing a blank box if there's no content at all
    const showContentTabs = sourceRequestsTab || popularArticlesTab || recentArticlesTab || statsTab;

    return (
      <div className={`content-tab ${className}`} style={{ visibility: showContentTabs ? 'visible' : 'hidden' }}>
        <div className="tab-links bg-white shadow-sm p-3">
          <ul className="nav m-0">
            {popularArticlesTab}
            {recentArticlesTab}
            {sourceRequestsTab}
            {statsTab}
          </ul>
        </div>
        <div className="tab-content">
          {this.renderTabContent()}
        </div>
      </div>
    );
  }
}

ContentTabs.propTypes = {
  canViewArticles: PropTypes.bool,
  canViewSourceRequests: PropTypes.bool,
  canViewStats: PropTypes.bool,
  className: PropTypes.string,
  currentEmployments: PropTypes.arrayOf(Object),
  currentUser: PropTypes.instanceOf(Object),
  publications: PropTypes.arrayOf(Object),
  reporter: PropTypes.instanceOf(Object),
  reporterId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ContentTabs.defaultProps = {
  canViewArticles: false,
  canViewSourceRequests: false,
  canViewStats: false,
  className: '',
  currentEmployments: undefined,
  currentUser: undefined,
  publications: undefined,
  reporter: undefined,
  userId: undefined,
};

export default ContentTabs;
