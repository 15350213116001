import React, { useState } from 'react';
import PropTypes from 'prop-types';

// alphabetized
import FollowupButton from './followup_button';
import FollowupModal from './followup_modal';
import vahoy from '../../javascript/vahoy';

function ReporterFollowup(props) {
  const {
    current_user: user,
    example_subject: subject,
    existing_conversation_path: existingConversationPath,
    mode,
    num_messages_already_exchanged: numMessagesAlreadyExchanged,
    reported_text: reportedText,
    source_user_name: sourceName,
    source_employment: sourceEmployment,
    source_slug: sourceSlug,
    avatar_url: avatarUrl,
    avatar_letters: avatarLetters,
    proposed_entities: proposedEntities,
    to,
  } = props;

  const isFollowupMode = mode === 'followupMode';

  const [path, setPath] = useState(existingConversationPath);
  const [numConversations, setNumConversations] = useState(numMessagesAlreadyExchanged);
  const [showPopup, setShowPopup] = useState(false);
  const [emailBody, setEmailBody] = useState('');

  const thanksBtnTxt = isFollowupMode ? 'Thanks, I used your pitch!' : "Let's talk!";
  const thxMessage = isFollowupMode ? 'Thanks for your pitch! I used it in my story. Read my story here: <link>' : "Thanks for your pitch! Let's talk more.";
  const sorryBtnTxt = isFollowupMode ? "Sorry, couldn't use your pitch." : "Sorry, I can't use this ...";
  const sorryMessage = isFollowupMode ? 'Thanks for pitching, but I wasn\'t able to use it this time around. Please, don\'t let this discourage you from responding to future requests. :)' : 'Thanks for pitching, but I won\'t be able to use it this time around. Please, don\'t let this discourage you from responding to future requests. :)';
  const scheduleInterviewBtnTxt = 'Schedule video interview';
  const scheduleInterviewMessage = "Your pitch piqued my interest! I'd love to explore its potential and learn more about your story and expertise through a video interview. Please let me know when you're available, and I'll set up a call.";

  const closeModal = () => setShowPopup(false);

  const showModal = (message) => {
    setEmailBody(message);
    setShowPopup(true);
    vahoy.track('ReporterFollowup#showModal');
  };

  const setMessagePath = (p) => {
    setPath(p);
    setNumConversations(1);
  };

  const renderFollowup = () => {
    const {
      id: userId,
      email,
      authentication_token: token,
    } = user;

    const replyBtnTxt = 'Reply';

    return (
      <div>
        <small className="float-start">
          <em>
            You can edit your message before sending:
          </em>
        </small>
        <br />
        <div className="follow-up">
          <span className="ps-1 float-start">
            <FollowupButton
              buttonText={thanksBtnTxt}
              clickEvent={() => showModal(thxMessage)}
              className="btn btn-outline-primary mb-1"
            />
          </span>
          <span className="ps-1 float-start">
            <FollowupButton
              buttonText={sorryBtnTxt}
              clickEvent={() => showModal(sorryMessage)}
              className="btn btn-outline-primary mb-1"
            />
          </span>
          <span className="ps-1 float-start">
            <FollowupButton
              buttonText={scheduleInterviewBtnTxt}
              clickEvent={() => showModal(scheduleInterviewMessage)}
              className="btn btn-outline-primary mb-1"
            />
          </span>
          <span className="ps-1 float-start float-md-end">
            <FollowupButton
              buttonText={replyBtnTxt}
              clickEvent={() => showModal('')}
              className="btn btn-lg btn-primary no-border mb-1"
            />
          </span>
          <FollowupModal
            userId={userId}
            email={email}
            token={token}
            toSguid={to}
            subject={subject}
            emailBody={emailBody}
            reportedText={reportedText}
            sourceName={sourceName}
            sourceEmployment={sourceEmployment}
            sourceSlug={sourceSlug}
            avatarUrl={avatarUrl}
            avatarLetters={avatarLetters}
            proposedEntities={proposedEntities}
            showPopup={showPopup}
            closeModal={closeModal}
            setMessagePath={setMessagePath}
          />
        </div>
      </div>
    );
  };

  const renderMessagePath = () => {
    const messagePluralized = numConversations === 1 ? 'message' : 'messages';
    return (
      <div className="message-path-wrapper">
        <span>You have already </span>
        <a href={path}>exchanged {numConversations} {messagePluralized} </a>
        <span>with {sourceName}. </span>
        <a className="btn btn-outline-primary btn-sm" href={path}>View conversation</a>
      </div>
    );
  };

  return numConversations > 0 ? renderMessagePath() : renderFollowup();
}

ReporterFollowup.propTypes = {
  current_user: PropTypes.instanceOf(Object).isRequired,
  example_subject: PropTypes.string,
  existing_conversation_path: PropTypes.string,
  mode: PropTypes.string.isRequired,
  num_messages_already_exchanged: PropTypes.number,
  reported_text: PropTypes.string,
  source_user_name: PropTypes.string,
  source_employment: PropTypes.string,
  source_slug: PropTypes.string,
  avatar_url: PropTypes.string,
  avatar_letters: PropTypes.string,
  proposed_entities: PropTypes.array,
  to: PropTypes.string.isRequired,
};

ReporterFollowup.defaultProps = {
  example_subject: undefined,
  existing_conversation_path: undefined,
  num_messages_already_exchanged: undefined,
  reported_text: undefined,
  source_user_name: undefined,
  source_employment: undefined,
  source_slug: undefined,
  avatar_url: undefined,
  avatar_letters: undefined,
  proposed_entities: undefined,
};

export default ReporterFollowup;
